import CapillaryLocationsTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-redness/capillary-locations/CapillaryLocationsTemplate';

import {
  REDNESS_DURATION,
  CAPILLARY_FACE_LOCATION,
  GENDER
} from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/Redness/CapillaryLocations/CapillaryLocationsTemplate'
};

const createStory = props => () => ({
  components: { CapillaryLocationsTemplate },
  storyProps: props,
  wrapStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template: `<div :style='$options.wrapStyles'>
    <capillary-locations-template v-bind="$options.storyProps" />
  </div>`
});

export const Base = createStory({
  firstBloodVesselOccurrance: REDNESS_DURATION.ONE_TO_THREE_YEARS,
  bloodVesselLocations: [CAPILLARY_FACE_LOCATION.NOSE, CAPILLARY_FACE_LOCATION.CHIN],
  gender: GENDER.FEMALE
});
